import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './scss/lecture-info.scss';
import { CMSLink } from 'components/cmsLink';
import ExternalLinkIcon from 'icons/external-link.svg';
import generateUniqueId from 'utilities/generateUniqueId';
import { GlobalStateContext } from 'containers/GlobalContextProvider';
import { getFullMonthAndDay, getFormattedTime } from 'utilities';

const Lecture = ({
  date,
  time,
  location,
  duration,
  title,
  lecturers,
  description,
  className,
}) => {
  const languageContext = useContext(GlobalStateContext);
  const translation = languageContext.dictionary.translations;
  const showLectureInfo =
    title === 'Final exam' ||
    title === 'Live Demo Day & Graduation' ||
    title === 'Kickoff & Project workshop';

  const lecturersLabel =
    lecturers && lecturers.length > 1
      ? translation['lecture.label.lecturers']
      : translation['lecture.label.lecturer'];

  const city =
    location === 'Klaipėda'
      ? translation['academyInfo.label.city.klaipeda']
      : location;

  const infoBlocks = [
    [
      {
        label: translation['academyInfo.label.date'],
        value: date && getFullMonthAndDay(date, languageContext.language.id),
      },
      {
        label: translation['academyInfo.label.time'],
        value: getFormattedTime(time),
      },
    ],
    [
      {
        label: translation['academyInfo.label.location'],
        value: city,
      },
      {
        label: translation['academyInfo.label.duration'],
        value: duration,
      },
    ],
  ];

  const renderLecturers = () => (
    <div className="lecture-info__item">
      <p className="lecture-info__label">{lecturersLabel}</p>
      <div className="lecture-info__description-wrapper">
        {lecturers.map((lecturer) => (
          <div
            key={generateUniqueId()}
            className="lecture-info__description lecture-info__description--lecturers"
          >
            <CMSLink className="lecture-info__link" linkUrl={lecturer.link}>
              {lecturer.name}
              <figure className="lecture-info__icon">
                <ExternalLinkIcon />
              </figure>
            </CMSLink>
          </div>
        ))}
      </div>
    </div>
  );

  const renderInfo = (info) =>
    info.value &&
    showLectureInfo && (
      <div key={`${info.label}${info.value}`} className="lecture-info__item">
        <p className="lecture-info__label">{info.label}</p>
        <p className="lecture-info__description">{info.value}</p>
      </div>
    );

  return (
    <div
      className={classNames('students-lecture', className && `${className}`)}
    >
      <h3 className="students-lecture__title">{title}</h3>
      <div className="students-lecture__info lecture-info">
        {infoBlocks.map((infoBlock) => (
          <div key={generateUniqueId()} className="lecture-info__items">
            {infoBlock.map((info) => renderInfo(info))}
          </div>
        ))}
        {lecturers && lecturers.length > 0 && renderLecturers()}
      </div>
      {description && description !== '' && (
        <p className="students-lecture__description">{description}</p>
      )}
    </div>
  );
};

Lecture.propTypes = {
  date: PropTypes.string,
  time: PropTypes.string,
  location: PropTypes.string,
  duration: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  className: PropTypes.string,
  lecturers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      link: PropTypes.string,
    })
  ),
};

export { Lecture };
